export const defaultCarleadCreationConfig = {
  locale: 'de',
  emailSuggestionEnabled: true,
  bookingUrl: '/appointment/9-0',
  confirmationUrl: '/appointment/9-0/confirmed',
  redirectOnSuccessUrl: '/booking/{hash}/',
  featuresFlags: {
    redirectToSelfEvaluation: true,
    selfEvaHideOnlinePriceBucket: true,
    dropOffEmailDelayInMinutes: 20,
    exactMileage: true,
  },
  stepsTimeTracking: {
    isEnabledFor: ['StepCompound'],
    options: {
      idleTimeout: 20,
      afterIDLETimeoutInMilliseconds: 10 * 1000,
    },
  },
  marketingAttributes: {
    "lead-mileagetextfield-112022-v1": 1,
    "repositioning-202209-v2": 1,
  },
  selfEvaNoBookingPageBucket: 1,
  selfEvaHideOnlinePriceBucket: 1,
  isMileageInputEnabled: true,
  trackLandingPageType: 'lead',
  syncQuestionnaireFieldsToCookie: true,
  returningCustomerPopup: [
    'lead'
  ]
};
